.table-search{
    width: 250px !important;
    max-width: 250px !important;
}
.formKendoGrid .k-detail-row{
    background-color: #f6f6f6 !important;
}

.v-list-item .v-list-item__subtitle, .v-list-item .v-list-item__title{
    line-height: 1.5 !important;
}

//***************data-table*****************//
.k-grid-header {
    font-size: 14px !important;
    .k-header{
        background-color: #f1f1f1;
    }
}

.k-grid{
    td, th{
        border-width: 0px 1px 1px 1px !important;
        padding: 12px 16px !important;
    }
    .k-hierarchy-cell{
        padding: 0px !important;
    }
    table tr{
        &:hover{
            background-color:#f1f1f1 !important;
        }
    }
}

.k-icon{
    .k-plus {
        padding: 10px 0 !important;
    }
}

// .line-multi{
//     background-color: white;
//     position: fixed;
//     z-index: 99;
//     // border: #0399c5 2px solid !important;
//     box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
//     border-radius: 5px;
//     width:"";
//     span{
//         white-space: pre-line !important;
//     }
// }

// td.line-multi{
//     cursor: pointer;
//     span{
//         white-space: pre;
//     }
//     .expand{
//         background-color: white;
//         position: fixed;
//         z-index: 99;
//         border: #0399c5 2px solid !important;
//         box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
//         border-radius: 5px;
//         span{
//             white-space: pre-line;
//         }
//     }
// }