%border{
    border:none !important;
    padding: 0.1rem !important;
    margin: 0.1rem !important;
}
#form{
    border: 1px solid #a8a8a8;
    margin:auto;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 60%;
    max-width: 60%;
}
.form-title{
    text-align: center !important;
    font-size: 2rem !important;
    font-weight: 700 !important;
}
.formio-component-form{
    padding: 0.5rem !important;
    margin: 0.1rem !important;
}

.formio-component-dataGrid th{
    background-color: rgb(233, 233, 233) !important;
}
.formio-component-dataGrid td{
    padding: 0.5rem !important;
}
.datagrid-table {
    textarea {
        @extend %border;
    }
    input{
        // border-bottom: none !important;
        @extend %border;
    }

}
.formio-component{
    img{
        cursor: pointer !important;
    }
    
  }



//--------------------[readOnly]--------------------
.formRender{
    .card.card-body{
        background-color: #fff !important;
    }
}


.form-control:disabled, .form-control[readonly]{
    background-color: #fff !important;
    
}
.formio-component{
    .selection[disabled=disabled]{
        border:none !important;
        border-radius: 0rem !important;
        border-bottom: 0.5px solid #a8a8a8 !important;
        cursor: default !important;

    }
    select[disabled=disabled]{
        border:none !important;
        border-radius: 0rem !important;
        border-bottom: 0.5px solid #a8a8a8 !important;
        -webkit-appearance: none !important;
        -moz-appearance: none !important;
    }
    select[disabled=disabled]::-ms-expand {
        display: none !important;
    }
    input[disabled=disabled]{
        border:none !important;
        border-bottom: 0.5px solid #a8a8a8 !important;
        border-radius: 0rem !important;
    }

    input:disabled{
        border:none !important;
        border-bottom: 0.5px solid #a8a8a8 !important;
        border-radius: 0rem !important;
    }

    input:disabled + .input-group-append{
        display: none !important;
    }

    .table{
        .selection[disabled=disabled]{
            @extend %border;
            .choices__item{
                cursor: text !important;
            }
        }
        input[disabled=disabled]{
            @extend %border;
            // border-bottom: 0.5px solid #a8a8a8;
            border-radius: 0rem !important;
        }
        .card{
            border: none !important;
            padding:0.5rem !important;
            background-color: #fff !important;
        }
    }
    
    .formio-drag-button{
        display: none;
    }
}
//******formio upload component START
.formio-component-file, .formio-component-syncoboxImage {
    .fileSelector{
        margin-left: 0px;
    }
    >div:not(.file):not(.field-wrapper)>div{
        display: inline-block;
        position: relative;
        margin: 3px;
        border: 1px solid #ececec;
        border-radius: 10px;
    }
    >div:not(.file):not(.field-wrapper)>span{
        display: block;
    }
    >div.field-wrapper{
        >div.field-label{
            margin: 3px;
            border: none;
        }
        >div.filed-content{
            border: none;
            >div:not(.file){
                display: flex;
                flex-wrap: wrap;
            }
            >div:not(.file)>div{
                display: inline-block;
                position: relative;
                margin: 3px;
                border: 1px solid #ececec;
                border-radius: 10px;
                flex: 1 1 17%;
                text-align: center;
                vertical-align: middle;
            }
        }
    }
    img{
        height: 100%;
        max-height: 250px;
        object-fit: contain;
        width: auto !important;
        max-width: 100%;
    }
    
    >div.file>div{
        display: flex;
    }
    .fa-remove{
        position: absolute;
        right: 0px;
        top: 0px;
        color: white !important;
        text-align: center;
        font-size: 1em;
        width: 1.4em;
        line-height: 1.4em;
        border-radius: 0.7em;
        cursor: pointer;
        background: #00000063;
    }
    .fa-remove::before{
        background-color: transparent;
    }
}
//formio upload component END******



//bootstrap conflict START******

button{
    &:focus{
        outline:none;
    }
}
.confirm-dialog.alert-dialog{
    max-width: 280px !important;
}

.k-widget.k-grid > .k-pager-wrap .k-pager-sizes select {
    border: solid 0.5px rgba(0,0,0,.42) !important;
    border-radius: 4px !important;
    padding: 0 10px !important;
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 2px;
}
//bootstrap conflict END******


/* Vuetify Style START***/

.v-text-field input{
    line-height: 16px !important;
  }

/* Vuetify Style END***/